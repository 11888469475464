<template>
  <span class="font-gray2" style="cursor: pointer" @click="callback($event)">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: ["url", "routeName", "isBlank"],
  methods: {
    callback(e) {
      if (this.url) {
        if (this.isBlank) window.open(this.url, "_blank");
        else this.$router.push(this.url);
      } else if (this.routeName) {
        this.$router.push({ name: this.routeName });
      } else {
        this.$emit("click", e);
      }
    },
  },
};
</script>

<style></style>
