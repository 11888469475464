<template>
  <v-autocomplete
    dense
    solo
    hide-details
    flat
    outlined
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :value="inputValue"
    :disabled="disabled"
    @input="updateInput">
    <template v-slot:label v-if="required">
      <span class="font-error">Required</span>
    </template>
    <template v-slot:append>
      <v-icon> mdi-chevron-down </v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemValue: {
      type: String,
      default: undefined,
    },
    itemText: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    updateInput(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.inputValue = this.value;
  },
};
</script>

<style></style>
