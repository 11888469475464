<template>
  <div class="bom">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">BOM (Bill of Material)</p>
      </div>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <div class="d-flex mb-4 gutter-mx-3 search-toolbar" v-if="!editMode">
        <!-- Column Select -->
        <div class="column-filter">
          <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="searchColumn">
            <template v-slot:append>
              <v-icon> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
        </div>

        <!-- Column Search -->
        <div class="search">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="searchText"
            placeholder="Insert Keyword to search"
            background-color="white"
            @keyup.enter="getBOMs(true)"></v-text-field>
        </div>

        <v-btn color="secondary" icon @click="getBOMs(true)" style="margin: 0; align-self: center">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn color="secondary" dark :elevation="0" @click="changeMode(true)">REGISTER & MODIFY</v-btn>
      </div>

      <!-- editMode head -->
      <div class="d-flex mb-4 gutter-mx-3" v-else>
        <v-spacer />

        <v-btn color="secondary" dark :elevation="0" :loading="saveLoading" @click="save">SAVE</v-btn>
        <v-btn color="secondary" dark :elevation="0" @click="changeMode(false)">CANCEL</v-btn>
      </div>

      <!-- #region custom-table -->
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th
                v-for="column in tableColumns"
                :key="column.text"
                :class="{ essential: column.essential && editMode, pointer: !editMode }"
                @click="!editMode && hasValue(column.sortValue) && changeSort(column.text)">
                <span v-if="column.text !== 'deleteRow'">{{ column.text }}</span>

                <i class="fa-solid fa-sort-down" v-if="!editMode && column.sort === 'desc'"></i>
                <i class="fa-solid fa-sort-up" v-if="!editMode && column.sort === 'asc'"></i>
              </th>
            </tr>
          </thead>
          <!-- #region bom이 없을 경우의 tbody -->
          <tbody v-if="!hasBOM && !editMode">
            <tr>
              <td :colspan="tableColumns.length" class="no-data">
                There is no BOM registered here.
                <br />
                Press the
                <v-btn color="secondary" dark :elevation="0" x-small @click="changeMode(true)">REGISTER & MODIFY</v-btn>
                button to create a new BOM.
              </td>
            </tr>
          </tbody>
          <!-- #endregion -->
          <!-- #region view mode의 tbody -->
          <tbody v-else-if="!editMode">
            <template v-for="(bom, bomIndex) in boms">
              <tr :key="`view ${bom.bomUUID} ${bomIndex}`" @click="bom.expanded = !bom.expanded">
                <!-- No -->
                <td>
                  <span v-if="sortColumn.text === 'No' && sortColumn.sort === 'asc'">
                    {{ (page - 1) * itemsPerPage + (bomIndex + 1) }}
                  </span>
                  <span v-else>
                    {{ totalCnt - bomIndex - (page - 1) * itemsPerPage }}
                  </span>
                </td>
                <!-- BRAND -->
                <td>
                  <span
                    class="hoverUnderline pointer hover-font-secondary"
                    @click.stop="searchBOM('offerBrand', bom.offerBrand)">
                    {{ bom.offerBrand }}
                  </span>
                </td>
                <!-- YEAR -->
                <td>
                  {{ bom.bomYear }}
                </td>
                <!-- SEASON -->
                <td>
                  {{ bom.season }}
                </td>
                <!-- GENDER -->
                <td>
                  {{ bom.gender }}
                </td>
                <!-- GARMENT FABRIC TYPE -->
                <td>
                  {{ bom.fabricType }}
                </td>
                <!-- GARMENT TYPE -->
                <td>
                  {{ bom.garmentType }}
                </td>
                <!-- DESIGN NO -->
                <td>
                  {{ bom.designNo }}
                </td>
                <!-- STYLE NO -->
                <td v-html="formatStyleNo(bom.bomStyleNoList)"></td>
                <!-- ORDER TYPE -->
                <td>
                  {{ bom.orderType }}
                </td>
                <!-- COVER NAME -->
                <td>
                  {{ bom.coverName }}
                </td>
                <!-- MODIFIED DATE / TIME -->
                <td>
                  {{  $m(bom.sysUdtDt).format("YY-MM-DD HH:mm"), }}
                </td>
                <!-- WORKED BY -->
                <td>
                  <span
                    class="hoverUnderline pointer hover-font-secondary"
                    @click.stop="searchBOM('workedBy', bom.workedBy)">
                    {{ bom.workedBy }}
                  </span>
                </td>
                <!-- STATUS -->
                <td>
                  {{ convertStatusText(bom.status) }}
                </td>
              </tr>
              <!-- #region Expanded-row -->
              <tr :key="'expanded-row' + bomIndex" v-if="bom.expanded" class="expanded-row">
                <td :colspan="tableColumns.length">
                  <div class="bom-defail d-flex" style="justify-content: space-around">
                    <!-- image -->
                    <div class="bom-image" style="width: 300px">
                      <img
                        v-if="hasValue(bom.attachDto.filePath)"
                        :src="`${$s3}${bom.attachDto.filePath}`"
                        @error="imgErrorHandler"
                        alt="image"
                        class="fw" />
                      <img v-else :src="require('images/img_default.png')" alt="image" class="fw" />

                      <v-btn
                        outlined
                        color="secondary"
                        :loading="bom.attachDto.imageUploadLoading"
                        @click="clickImageBtn(bom.bomUUID)">
                        MODIFY IMAGE
                      </v-btn>
                      <input
                        type="file"
                        ref="files"
                        style="display: none"
                        :id="`${bom.bomUUID}_img_btn`"
                        accept="image/*"
                        @change="changeImage(bom)" />
                    </div>

                    <!-- history -->
                    <div class="bom-info">
                      <input-switch
                        :isActive="bom.isComplete"
                        activeColor="#EB5757"
                        inactiveColor="#3A989E"
                        :label="bom.isComplete ? convertStatusText('COMPLETE') : convertStatusText('PROGRESS')"
                        @click="switchClicked(bom)" />

                      <div class="bom-history">
                        <div class="title d-flex">
                          <span>History</span>
                          <v-spacer />
                          <small-link-text v-if="hasValue(bom.bomWorkHistSelectDtoList)" @click="historyMore(bom)">
                            more
                          </small-link-text>
                        </div>
                        <div class="list" v-if="hasValue(bom.bomWorkHistSelectDtoList)">
                          <div
                            v-for="(history, historyIndex) in bom.bomWorkHistSelectDtoList.slice(0, 10)"
                            :key="`history div ${bom.bomUUID} ${historyIndex}`">
                            <span class="value">
                              {{ `${$m(history.sysRegDt).format("YY/MM/DD")} ${history.workType}` }}</span
                            >
                            <span :class="history.workItem">{{ history.workItem }}</span>
                            <span class="font-blue1">{{ history.workUserName }}</span>
                          </div>
                        </div>
                        <div v-else class="no-history">
                          <div class="text-center">No History</div>
                        </div>
                      </div>
                    </div>

                    <!-- tab -->
                    <div class="bom-content">
                      <div class="d-flex head">
                        <h1>{{ bom.tab === 0 ? `CBD (Cost Break Down)` : `MCL (Material Check List)` }}</h1>
                        <v-tabs v-model="bom.tab">
                          <v-tab>CBD ({{ bom.bomCbdSelectDtoList.length || 0 }})</v-tab>
                          <v-tab>MCL ({{ bom.bomMclSelectDtoList.length || 0 }})</v-tab>
                        </v-tabs>
                      </div>

                      <div class="contents">
                        <v-tabs-items v-model="bom.tab">
                          <!-- CBD -->
                          <v-tab-item>
                            <v-data-table
                              hide-default-footer
                              disable-sort
                              :headers="CBDTableColumns"
                              :items="bom.bomCbdSelectDtoList"
                              :page.sync="bom.CBD.page"
                              :items-per-page="itemsPerPage"
                              @page-count="bom.CBD.pageCount = $event"
                              @click:row="item => clickedCBDRow(item)"
                              :no-results-text="$t('common.text.noMatchingResult')"
                              class="CBD">
                              <template slot="no-data">
                                There aren't created CBDs.
                                <br />
                                Press the button below to create a new CBD.
                                <br />
                                <v-btn
                                  outlined
                                  small
                                  :disabled="bom.isComplete"
                                  color="secondary"
                                  @click="item => clickedCBDRow(item, true, bom)">
                                  CREATE A NEW CBD
                                </v-btn>
                              </template>
                              <template v-slot:[`item.sysUdtDt`]="{ item }">
                                <div class="modified">
                                  <p class="ma-0">{{ $m(item.sysUdtDt).format("yyyy-MM-DD HH:mm") }}</p>
                                  <p class="font-blue1 ma-0">{{ item.modified }}</p>
                                </div>
                              </template>
                              <template v-slot:[`item.status`]="{ item }">
                                <p :class="item.status === 'OPEN' ? 'font-blue1 ma-0' : 'font-error ma-0'">
                                  {{ item.status }}
                                </p>
                              </template>
                            </v-data-table>

                            <div class="text-center pt-2" v-if="bom.CBD.pageCount > 0">
                              <v-pagination
                                v-model="bom.CBD.page"
                                :length="bom.CBD.pageCount"
                                color="secondary"
                                :total-visible="$pagePV"></v-pagination>
                            </div>

                            <v-btn
                              v-if="hasValue(bom.bomCbdSelectDtoList) && bom.bomCbdSelectDtoList.length !== 0"
                              :disabled="bom.isComplete"
                              outlined
                              small
                              color="secondary"
                              @click="item => clickedCBDRow(item, true, bom)">
                              CREATE A NEW CBD
                            </v-btn>
                          </v-tab-item>

                          <!-- MCL -->
                          <v-tab-item>
                            <v-data-table
                              hide-default-footer
                              disable-sort
                              :headers="MCLTableColumns"
                              :items="bom.bomMclSelectDtoList"
                              :page.sync="bom.MCL.page"
                              :items-per-page="itemsPerPage"
                              @page-count="bom.MCL.pageCount = $event"
                              @click:row="item => clickedMCLRow(item)"
                              :no-results-text="$t('common.text.noMatchingResult')"
                              class="MCL">
                              <template slot="no-data">
                                There aren't created MCLs.
                                <br />
                                Press the button below to create a new MCL.
                                <br />
                                <v-btn
                                  outlined
                                  small
                                  :disabled="bom.isComplete"
                                  color="secondary"
                                  @click="item => clickedMCLRow(item, true, bom)">
                                  CREATE A NEW MCL
                                </v-btn>
                              </template>
                              <template v-slot:[`item.totalAmount`]="{ item }">
                                <div class="total-amount">
                                  <p class="ma-0">
                                    Required : {{ numberWithComma(fixedNumber(item.required, $mathFix, true)) || 0 }}
                                  </p>
                                  <p class="ma-0">
                                    Issued : {{ numberWithComma(fixedNumber(item.issued, $mathFix, true)) || 0 }}
                                  </p>
                                </div>
                              </template>
                              <template v-slot:[`item.sysUdtDt`]="{ item }">
                                <div class="modified">
                                  <p class="ma-0">{{ $m(item.sysUdtDt).format("yyyy-MM-DD HH:mm") }}</p>
                                  <p class="font-blue1 ma-0">{{ item.modified }}</p>
                                </div>
                              </template>
                              <template v-slot:[`item.status`]="{ item }">
                                <p :class="item.status === 'OPEN' ? 'font-blue1 ma-0' : 'font-error ma-0'">
                                  {{ item.status }}
                                </p>
                              </template>
                            </v-data-table>

                            <div class="text-center pt-2" v-if="bom.MCL.pageCount > 0">
                              <v-pagination
                                v-model="bom.MCL.page"
                                :length="bom.MCL.pageCount"
                                color="secondary"
                                :total-visible="$pagePV"></v-pagination>
                            </div>

                            <v-btn
                              v-if="hasValue(bom.bomMclSelectDtoList) && bom.bomMclSelectDtoList.length !== 0"
                              outlined
                              small
                              :disabled="bom.isComplete"
                              color="secondary"
                              @click="item => clickedMCLRow(item, true, bom)">
                              CREATE A NEW MCL
                            </v-btn>
                          </v-tab-item>
                        </v-tabs-items>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- #endregion -->
            </template>
          </tbody>
          <!-- #endregion -->
          <!-- #region edit mode의 tbody -->
          <tbody v-else>
            <tr>
              <td :colspan="tableColumns.length" class="register pointer" @click="addBOM">
                <v-btn x-small color="secondary" :elevation="0">REGISTER</v-btn> A NEW BOM
              </td>
            </tr>
            <tr v-for="(bom, bomIndex) in boms" :key="`edit ${bom.bomUUID} ${bomIndex} ${bom.tempRegDt}`">
              <!-- No -->
              <td>
                <span v-if="sortColumn.text === 'No' && sortColumn.sort === 'asc'">
                  {{ (page - 1) * itemsPerPage + (bomIndex + 1) }}
                </span>
                <span v-else>{{ (totalCnt || boms.length) - bomIndex - (page - 1) * itemsPerPage }}</span>
              </td>
              <!-- BRAND -->
              <td>
                <input-autocomplete
                  :items="$store.getters['company/getAllBrands']($store.getters.getCmpyUUID)"
                  :required="true"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.offerBrand" />
              </td>
              <!-- YEAR -->
              <td>
                <input-autocomplete
                  :items="years"
                  :required="true"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.bomYear" />
              </td>
              <!-- SEASON -->
              <td>
                <input-autocomplete
                  :items="$store.state.company.company[$store.getters.getCmpyUUID].season"
                  itemText="itemName"
                  itemValue="itemName"
                  :required="true"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.season" />
              </td>
              <!-- GENDER -->
              <td>
                <input-autocomplete
                  :items="$store.state.code.common.gender"
                  itemText="itemName"
                  itemValue="itemName"
                  :required="true"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.gender" />
              </td>
              <!-- GARMENT FABRIC TYPE -->
              <td>
                <input-autocomplete
                  :items="$store.state.company.company[$store.getters.getCmpyUUID].garmentFabricType"
                  itemText="itemName"
                  itemValue="itemName"
                  :required="false"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.fabricType" />
              </td>
              <!-- GARMENT TYPE -->
              <td>
                <input-autocomplete
                  :items="$store.state.code.common.garmentType"
                  itemText="itemName"
                  itemValue="itemName"
                  :required="false"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.garmentType" />
              </td>
              <!-- DESIGN NO -->
              <td>
                <v-text-field
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  v-model="bom.designNo"
                  :disabled="bom.canEdit === 'N'"
                  background-color="white">
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                </v-text-field>
              </td>
              <!-- STYLE NO -->
              <td>
                <div
                  class="d-flex align-center"
                  v-for="(style, i) in bom.bomStyleNoList"
                  :key="`bomStyle ${bom.bomUUID} ${i}`">
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    hide-details
                    :disabled="style.isUse === 'Y'"
                    v-model="style.styleNo"
                    background-color="white" />

                  <v-btn
                    style="margin-left: 2px"
                    color="secondary"
                    outlined
                    fab
                    small
                    :disabled="i !== 0 && style.isUse === 'Y'"
                    @click="styleNoBtnClick(i, bom)">
                    <v-icon> {{ `mdi-${i === 0 ? "plus" : "minus"}` }} </v-icon>
                  </v-btn>
                </div>
              </td>
              <!-- ORDER TYPE -->
              <td>
                <input-autocomplete
                  :items="$store.state.company.company[$store.getters.getCmpyUUID].orderType"
                  itemText="itemName"
                  itemValue="itemName"
                  :required="true"
                  :disabled="bom.canEdit === 'N'"
                  v-model="bom.orderType" />
              </td>
              <!-- COVER NAME -->
              <td>
                <v-text-field
                  solo
                  flat
                  dense
                  outlined
                  hide-details
                  v-model="bom.coverName"
                  :disabled="bom.canEdit === 'N'"
                  background-color="white">
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                </v-text-field>
              </td>
              <!-- MODIFIED DATE / TIME -->
              <td>
                <input
                  type="file"
                  ref="files"
                  style="display: none"
                  accept="image/*"
                  @change="changeImage(bom, bomIndex)" />

                <!-- 이미지가 있을 때 -->
                <div v-if="hasValue(bom.attachDto) && hasValue(bom.attachDto.fileName)">
                  <v-menu v-model="bom.attachDto.open" :close-on-content-click="false" :nudge-width="200" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="hoverUnderline pointer" v-bind="attrs" v-on="on">
                        {{ bom.attachDto.fileName }}
                      </span>
                    </template>

                    <v-card class="admin-popover-license" min-width="350px">
                      <img
                        v-if="hasValue(bom.attachDto.changeImageThumbnail)"
                        :src="bom.attachDto.changeImageThumbnail"
                        alt="image"
                        class="fw" />
                      <img v-else :src="`${$s3}${bom.attachDto.filePath}`" alt="image" class="fw" />

                      <v-btn outlined small color="secondary" @click="clickImageBtn(bomIndex)"> MODIFY IMAGE </v-btn>
                    </v-card>
                  </v-menu>
                </div>

                <!-- 이미지가 없을 때 -->
                <div v-else class="bomImage">
                  <v-btn outlined small color="secondary" @click="clickImageBtn(bomIndex)"> IMAGE UPLOAD </v-btn>
                </div>
              </td>
              <!-- WORKED BY -->
              <td>
                {{ bom.workedBy }}
              </td>
              <!-- STATUS -->
              <td>
                {{ convertStatusText(bom.status) }}
              </td>
              <!-- row delete -->
              <td>
                <v-btn
                  color="secondary"
                  outlined
                  fab
                  small
                  :disabled="bom.canEdit === 'N'"
                  @click="deleteBOMClicked(bom)">
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <!-- #endregion -->
        </table>

        <!-- pagination을 editMode일때 안보여주는 이유 : -->
        <!-- editMode일 때, add Item을 했다하여 현재 아이템 중 1개를 뒤페이지로 보내지 않고, -->
        <!-- remove Item을 했다하여 아이템을 1개 당겨오지도 않음. -->
        <!-- page 이동 시, 새로운 item을 받아오면 기존 수정한 내용도 날아감. -->
        <div class="text-center pt-2" v-if="!editMode && pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>
      </div>
    </v-card>

    <history-view :show.sync="historyViewInfo.show" :bom="historyViewInfo.targetBOM" />

    <confirm :show.sync="deleteConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="deleteBOM">
      Are you trying to delete the selected BOM? <br />
      This action will not recover BOM data.
    </confirm>

    <confirm :show.sync="statusConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="statusChange">
      Are you trying to close the BOM data?<br />
      The only 'Completed' BOM data will be ready for the next step.
    </confirm>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { hasValue, isDuplicate, _deepCopy, numberWithComma, fixedNumber } from "@/utils/util";
import HistoryView from "@/components/company/bom/HistoryView.vue";
import codeHandler from "@/utils/codeHandler";
import InputAutocomplete from "@/components/common/inputs/InputAutocomplete.vue";
import InputSwitch from "@/components/common/inputs/InputSwitch.vue";
import SmallLinkText from "@/components/common/texts/SmallLinkText.vue";
import Confirm from "@/components/common/cards/Confirm.vue";
import { bomStatus } from "@/utils/statusHandler";

export default {
  components: { HistoryView, InputAutocomplete, InputSwitch, SmallLinkText, Confirm },
  props: {
    bomUUID: {
      type: String,
      default: "",
    },
    loadSearchOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      boms: [],
      backupBOMs: [],
      searchColumn: "ALL",
      searchText: "",
      deleteLoading: false,
      saveLoading: false,
      loading: false,
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      years: [],
      historyViewInfo: {
        show: false,
        targetBOM: null,
      },
      deleteConfirmShow: false,
      deleteConfirmTarget: null,
      statusConfirmShow: false,
      statusConfirmTarget: null,
      columns: [
        //text: 컬럼 이름, sortValue: 페이지네이션 솔트 컬럼명, essential: 필수여부,
        //tableShow: 테이블 표시 여부, searchShow: 셀렉트박스 표시 여부, sort: 페이지네이션 솔트 순서
        //value: 키값, default: 기본값
        { text: "ALL", sortValue: "ALL", essential: false, tableShow: false },
        { text: "No", sortValue: "sysRegDt", essential: false, searchShow: false, sort: "desc" },
        { text: "BRAND", sortValue: "offerBrand", essential: true, sort: "", value: "offerBrand", default: "" },
        { text: "YEAR", sortValue: "bomYear", essential: true, sort: "", value: "bomYear", default: "" },
        { text: "SEASON", sortValue: "season", essential: true, sort: "", value: "season", default: "" },
        { text: "GENDER", sortValue: "gender", essential: true, sort: "", value: "gender", default: "" },
        {
          text: "GARMENT FABRIC TYPE",
          sortValue: "fabricType",
          essential: false,
          sort: "",
          value: "fabricType",
          default: "",
        },
        {
          text: "GARMENT TYPE",
          sortValue: "garmentType",
          essential: false,
          sort: "",
          value: "garmentType",
          default: "",
        },
        { text: "DESIGN #", sortValue: "designNo", essential: true, sort: "", value: "designNo", default: "" },
        {
          text: "STYLE #",
          sortValue: "styleNo",
          essential: false,
          sort: "",
          value: "bomStyleNoList",
          default: [{ isUse: "N", styleNo: "" }],
        },
        { text: "ORDER TYPE", sortValue: "orderType", essential: true, sort: "", value: "orderType", default: "" },
        { text: "COVER NAME", sortValue: "coverName", essential: true, sort: "", value: "coverName", default: "" },
        {
          text: "MODIFIED DATE / TIME",
          sortValue: "sysUdtDt",
          essential: false,
          sort: "",
          searchShow: false,
          value: "sysUdtDt",
          default: "",
        },
        {
          text: "WORKED BY",
          sortValue: "workedBy",
          essential: false,
          sort: "",
          value: "workedBy",
          default: this.$store.state.user.name,
        },
        { text: "STATUS", sortValue: "status", essential: false, sort: "", value: "status", default: "PROGRESS" },
        { text: "deleteRow", essential: false, searchShow: false },
      ],
      CBDTableColumns: [
        {
          text: "Name (Description)",
          value: "cbdTitle",
        },
        {
          text: "FINAL PRICE",
          value: "finalPrice",
        },
        {
          text: "Profit",
          value: "profit",
        },
        {
          text: "Qty",
          value: "orderQty",
        },
        {
          text: "Modified",
          value: "sysUdtDt",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      MCLTableColumns: [
        {
          text: "Name (Description)",
          value: "mclName",
        },
        {
          text: "Manufacturer",
          value: "manufactureCmpy",
        },
        {
          text: "Total Amount",
          value: "totalAmount",
        },
        {
          text: "Modified",
          value: "sysUdtDt",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
    };
  },
  methods: {
    imgErrorHandler(e) {
      e.target.src = require("images/img_default.png");
    },
    changeSort(text) {
      this.columns.forEach(column => {
        if (column.text === text) column.sort = column.sort === "desc" ? "asc" : "desc";
        else column.sort = "";
      });
      this.getBOMs(true);
    },
    changeMode(val, notChange) {
      if (val === true) {
        this.backupBOMs = _deepCopy(this.boms);
        if (!this.hasBOM) this.addBOM();
      } else {
        if (!notChange) this.boms = _deepCopy(this.backupBOMs);
        else this.getBOMs();
      }

      this.totalCnt = this.boms[0]?.totalCnt;

      this.editMode = val;
    },
    getSearchOption() {
      return {
        cmpyUUID: this.$store.getters.getCmpyUUID,
        currentPageNo: this.page,
        filterColumn: this.searchColumn === "bomStyleNoList" ? "styleNo" : this.searchColumn,
        searchText: this.searchText,
        sortColumn: this.sortColumn.sortValue,
        sortType: this.sortColumn.sort,
      };
    },
    getBOMs(changeFilters, openBOM, openBOMUUID) {
      this.loading = true;
      if (changeFilters) this.page = 1;

      this.$http
        .get("/bom/list", {
          data: this.getSearchOption(),
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              const boms = res.data.data.map(m => {
                return this.bomSetting(m);
              });
              this.boms = boms;
              this.totalCnt = res.data.data[0]?.totalCnt || 0;

              if (openBOM === true) {
                const openBOM = boms.find(b => b.bomUUID === openBOMUUID);
                if (openBOM) openBOM.expanded = true;
              }
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    bomSetting(bom) {
      const newBOM = {
        ...bom,
        checked: false,
        expanded: false,
        tab: 0, //0: CBD, 1: MCL
        CBD: {
          page: 1,
          pageCount: 0,
        },
        MCL: {
          page: 1,
          pageCount: 0,
        },
      };

      newBOM.isComplete = newBOM.status === "COMPLETE";

      if (!hasValue(newBOM.attachDto))
        newBOM.attachDto = {
          filePath: "",
          fileName: "",
          file: null,
          open: false,
          changeImageThumbnail: null,
          imageUploadLoading: false,
        };
      else {
        newBOM.attachDto.file = null;
        newBOM.attachDto.open = false;
        newBOM.attachDto.changeImageThumbnail = null;
        newBOM.attachDto.imageUploadLoading = false;
      }

      return newBOM;
    },
    addBOM() {
      const bom = {};
      this.columns
        .filter(c => hasValue(c.value))
        .forEach(c => {
          bom[c.value] = _deepCopy(c.default);
        });

      bom.tempRegDt = Date.now();
      this.boms.unshift(_deepCopy(this.bomSetting(bom)));
      this.totalCnt += 1;
    },
    // deleteBOMs() {
    //   //체크 된 아이템이 있는지
    //   if (!this.boms.some(bom => bom.checked)) return this.$toast.warning("There are no selected bom");

    //   //삭제 여부 확인
    //   let really = confirm(`Are you sure to delete the selected BOM?\r\nThe deleted BOM shall not be restored.`);

    //   if (really) {
    //     //생성 중인 BOM은 직접 삭제
    //     let index = this.boms.length - 1;
    //     while (index >= 0) {
    //       const b = this.boms[index];
    //       if (b.checked && !hasValue(b.bomUUID)) {
    //         this.totalCnt -= 1;
    //         this.boms.splice(index, 1);
    //       }
    //       index -= 1;
    //     }

    //     //생성된 BOM은 서버로
    //     const filteredBOMs = this.boms.filter(bom => bom.checked);
    //     if (filteredBOMs.length < 1) return;

    //     this.deleteLoading = true;

    //     this.$http
    //       .patch("/bom", {
    //         bomUUID: filteredBOMs.map(b => b.bomUUID),
    //         cmpyUUID: this.$store.getters.getCmpyUUID,
    //       })
    //       .then(res => {
    //         if (res.data.status === 200) {
    //           //선택된 bom 삭제
    //           let index = this.boms.length - 1;
    //           while (index >= 0) {
    //             const b = this.boms[index];
    //             if (b.checked) {
    //               this.boms.splice(index, 1);
    //               this.totalCnt -= 1;
    //             }
    //             index -= 1;
    //           }

    //           index = this.backupBOMs.length - 1;
    //           while (index >= 0) {
    //             const b = this.backupBOMs[index];
    //             if (b.checked) this.backupBOMs.splice(index, 1);
    //             index -= 1;
    //           }
    //         } else codeHandler(res.data);
    //       })
    //       .finally(() => (this.deleteLoading = false));
    //   }
    // },
    deleteBOMClicked(bom) {
      this.deleteConfirmShow = true;
      this.deleteConfirmTarget = bom;
    },
    deleteBOM() {
      //삭제 여부 확인
      const bom = this.deleteConfirmTarget;
      if (!hasValue(bom.bomUUID)) {
        //생성 중인 BOM일 경우 직접 삭제하고 종료
        this.boms.splice(
          this.boms.findIndex(b => b === bom),
          1,
        );
        this.totalCnt -= 1;
      } else {
        //생성된 BOM은 서버로 요청
        this.deleteLoading = true;

        this.$http
          .patch("/bom", {
            bomUUID: [bom.bomUUID],
            cmpyUUID: this.$store.getters.getCmpyUUID,
          })
          .then(res => {
            if (res.data.status === 200) {
              //선택된 bom 삭제
              this.boms.splice(
                this.boms.findIndex(b => b.bomUUID === bom.bomUUID),
                1,
              );

              this.totalCnt -= 1;

              this.backupBOMs.splice(
                this.backupBOMs.findIndex(b => b.bomUUID === bom.bomUUID),
                1,
              );
            } else codeHandler(res.data);
          })
          .finally(() => (this.deleteLoading = false));
      }
    },
    formatStyleNo(styleNoList) {
      let text = "";
      styleNoList.forEach((s, i) => {
        text += s.styleNo || "";
        if (i !== styleNoList.length - 1) text += "<br />";
      });
      return text;
    },
    styleNoBtnClick(index, bom) {
      if (index === 0) bom.bomStyleNoList.push({ isUse: "N", styleNo: "" });
      else bom.bomStyleNoList.splice(index, 1);
    },
    searchBOM(column, value) {
      this.searchColumn = column;
      this.searchText = value;
      this.getBOMs(true);
    },
    changeImage(bom, index) {
      let obj = this.editMode ? this.$refs.files[index] : document.getElementById(`${bom.bomUUID}_img_btn`);

      if (obj.files.length < 1 || !obj.files[0]) return;
      bom.attachDto.fileName = obj.files[0].name;
      bom.attachDto.file = obj.files[0];

      if (this.editMode) {
        const reader = new FileReader();
        reader.onload = e => (bom.attachDto.changeImageThumbnail = e.target.result);
        reader.readAsDataURL(obj.files[0]);
      } else {
        bom.attachDto.imageUploadLoading = true;

        const formData = new FormData();
        formData.append("file", obj.files[0]);
        formData.append("cmpyUUID", this.$store.getters.getCmpyUUID);
        this.$http
          .post(`/bom/${bom.bomUUID}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            if (res.data.status === 200) {
              bom.attachDto.filePath = res.data.data.attachDto.filePath;
            } else codeHandler(res.data);
          })
          .finally(() => (bom.attachDto.imageUploadLoading = false));
      }
    },
    clickImageBtn(obj) {
      //obj = editMode ?  index : uuid
      if (this.editMode) this.$refs.files[obj].click();
      else document.getElementById(`${obj}_img_btn`).click();
    },
    historyMore(bom) {
      this.historyViewInfo.targetBOM = bom;
      this.historyViewInfo.show = true;
    },
    switchClicked(bom) {
      if (!bom.isComplete) {
        this.statusConfirmShow = true;
        this.statusConfirmTarget = bom;
      } else {
        this.statusConfirmTarget = bom;
        this.statusChange();
      }
    },
    statusChange() {
      const bom = this.statusConfirmTarget;

      const newStatus = bom.status === "COMPLETE" ? "PROGRESS" : "COMPLETE";
      this.$http
        .put("/bom", {
          bomUUID: bom.bomUUID,
          cmpyUUID: this.$store.getters.getCmpyUUID,
          status: newStatus,
        })
        .then(res => {
          if (res.data.status === 200) {
            bom.bomWorkHistSelectDtoList = res.data.data.bomWorkHistSelectDtoList;
            bom.status = newStatus;
            bom.isComplete = !bom.isComplete;
          } else codeHandler(res.data);
        });
    },
    save() {
      //필수값 체크
      const requireColumn = this.columns.filter(c => c.tableShow !== false && c.essential === true);
      const requireColumnLength = requireColumn.length;
      const bomLength = this.boms.length;

      for (let bi = 0; bi < bomLength; bi++) {
        const bom = this.boms[bi];
        for (let ci = 0; ci < requireColumnLength; ci++) {
          const col = requireColumn[ci];
          if (!hasValue(bom[col.value])) return this.$toast.warning("Please enter all the required items.");
        }
        if (isDuplicate(bom.bomStyleNoList.map(s => s.styleNo)))
          return this.$toast.warning("Style# is cannot be duplicated.");
      }

      //파라미터 생성
      const formData = new FormData();
      const cmpyUUID = this.$store.getters.getCmpyUUID;
      let cnt = 0;
      let styleNoCnt = 0;
      for (let bi = 0; bi < bomLength; bi++) {
        const bom = this.boms[bi];

        //변경된 bom만 서버에 전송
        let changed = false;
        if (hasValue(bom.bomUUID)) {
          const orgBom = this.backupBOMs.find(b => b.bomUUID === bom.bomUUID);
          if (!orgBom) continue;

          changed =
            orgBom.offerBrand !== bom.offerBrand ||
            orgBom.bomYear !== bom.bomYear ||
            orgBom.season !== bom.season ||
            orgBom.gender !== bom.gender ||
            orgBom.fabricType !== bom.fabricType ||
            orgBom.garmentType !== bom.garmentType ||
            orgBom.designNo !== bom.designNo ||
            orgBom.orderType !== bom.orderType ||
            orgBom.coverName !== bom.coverName ||
            orgBom.bomStyleNoList.map(s => s.styleNo).join("") !== bom.bomStyleNoList.map(s => s.styleNo).join("") ||
            (bom.attachDto.file !== undefined && bom.attachDto.file !== null);
        } else {
          //새로 생성된 것은 무조건 서버 전송
          changed = true;
        }

        if (changed) {
          if (hasValue(bom.bomUUID)) {
            formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.bomUUID`, bom.bomUUID);
          }
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.cmpyUUID`, cmpyUUID);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.offerBrand`, bom.offerBrand);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.bomYear`, bom.bomYear);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.season`, bom.season);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.gender`, bom.gender);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.fabricType`, bom.fabricType);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.garmentType`, bom.garmentType);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.designNo`, bom.designNo);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.orderType`, bom.orderType);
          formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.coverName`, bom.coverName);

          /** BOM StyleNoList 작업
           * 하나의 BOM에서 중복되는 StyleNO는 있을 수 없다.
           * StyleNo는 공백이 올 수 없다.
           * StyleNo가 하나도 없을 경우 Design No로 채워준다.
           */

          styleNoCnt = 0;
          bom.bomStyleNoList.forEach(s => {
            if (hasValue(s.styleNo?.trim())) {
              formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.bomStyleNoList[${styleNoCnt}]`, s.styleNo);
              styleNoCnt++;
            }
          });
          if (styleNoCnt === 0) {
            formData.append(`bomInsertRequestDtoList[${cnt}].bomInsertDto.bomStyleNoList[0]`, bom.designNo);
          }

          //bom attachDto 작업
          if (bom.attachDto.file !== undefined && bom.attachDto.file !== null) {
            formData.append(`bomInsertRequestDtoList[${cnt}].file`, bom.attachDto.file);
          }
          cnt++;
        }
      }

      //post
      this.saveLoading = true;
      this.$http
        .post("/bom", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Successfully saved");
            this.changeMode(false, true);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    saveSearchOption(targetUUID, bomUUID) {
      const searchOption = this.getSearchOption();
      searchOption.targetUUID = targetUUID;
      searchOption.bomUUID = bomUUID;
      this.$store.commit("user/setBomSearchOption", searchOption);
    },
    clickedCBDRow(cbd, isCreated, bom) {
      if (!isCreated) this.saveSearchOption(cbd.cbdUUID, cbd.bomUUID);
      else this.saveSearchOption("create", bom.bomUUID);

      this.$router.push({
        name: "CBD",
        params: isCreated ? { cbdUUID: "create", bomInfo: bom } : { cbdUUID: cbd.cbdUUID },
        query: {
          fromSearch: true,
        },
      });
    },
    clickedMCLRow(mcl, isCreated, bom) {
      if (!isCreated) this.saveSearchOption(mcl.mclUUID, mcl.bomUUID);
      else this.saveSearchOption("create", bom.bomUUID);

      this.$router.push({
        name: "MCL",
        params: isCreated ? { mclUUID: "create", bomInfo: bom } : { mclUUID: mcl.mclUUID },
        query: {
          fromSearch: true,
        },
      });
    },
    registWatchPage() {
      this.$watch("page", newVal => {
        this.page = newVal;
        this.getBOMs();
      });
    },
    convertStatusText: bomStatus,
    numberWithComma,
    hasValue,
    fixedNumber,
  },
  computed: {
    tableColumns() {
      return !this.editMode
        ? this.columns.filter(c => c.tableShow !== false && c.text !== "deleteRow")
        : this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    hasBOM() {
      return hasValue(this.boms);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
    sortColumn() {
      return this.columns.find(c => hasValue(c.sort));
    },
    allCheck: {
      get() {
        return this.boms.every(b => b.checked);
      },
      set(value) {
        this.boms.forEach(b => {
          b.checked = value;
        });
      },
    },
  },
  created() {
    const nowYear = new Date().getFullYear();
    this.years = Array.from({ length: 11 }, (_, i) => (nowYear - 5 + i).toString()); //현재년도 기준 -5 ~ +5년

    //#region get BOM List
    if (this.loadSearchOption === true) {
      //bom list에서 cbd/mcl로 진입 후, 뒤로가기 시
      const searchOption = this.$store.state.user.bomSearchOption;
      if (searchOption && typeof searchOption === "object" && Object.keys(searchOption).length > 0) {
        this.page = searchOption.currentPageNo;
        this.searchColumn = searchOption.filterColumn;
        this.searchText = searchOption.searchText;
        this.columns.forEach(column => {
          if (column.sortValue === searchOption.sortColumn) column.sort = searchOption.sortType;
          else column.sort = "";
        });
        this.registWatchPage();

        this.getBOMs(false, true, searchOption.bomUUID);
      }
    } else if (hasValue(this.bomUUID)) {
      //bom list가 아닌 곳에서 cbd/mcl로 진입 후, 뒤로가기 시
      //또는 특정 bom UUID로 bom 조회가 필요한 경우
      this.loading = true;
      this.$http
        .get("/bom/page-no", {
          data: {
            bomUUID: this.bomUUID,
            cmpyUUID: this.$store.getters.getCmpyUUID,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.page = res.data.data;
            this.getBOMs(false, true, this.bomUUID);
            this.registWatchPage();
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    } else {
      //bom list로 일반 진입
      this.getBOMs();
      this.registWatchPage();
    }
    //#endregion
  },
};
</script>

<style></style>
