<template>
  <v-dialog v-model="dialogShow" min-width="300px" content-class="company history-view customEditModal">
    <v-card class="pa-5">
      <h1>History of "{{ coverName }}"</h1>

      <div class="mt-10 content bom-history">
        <div class="list">
          <div v-for="(history, historyIndex) in bomWorkHistSelectDtoList" :key="'root ' + historyIndex">
            <span class="value">
              {{ `${$m(history.sysRegDt).format("YY/MM/DD")} ${bomStatus(history.workType)}` }}</span
            >
            <span :class="history.workItem">{{ history.workItem }}</span>
            <span class="font-blue1">{{ history.workUserName }}</span>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { bomStatus } from "@/utils/statusHandler";
export default {
  props: ["show", "bom"],
  methods: {
    bomStatus,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    coverName() {
      return this.bom?.coverName || "";
    },
    bomWorkHistSelectDtoList() {
      return this.bom?.bomWorkHistSelectDtoList || [];
    },
  },
};
</script>

<style></style>
